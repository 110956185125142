import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Container, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AppIcon from '../Media/app-icon.svg';

const theme = createTheme({
  palette: {
    primary: {
      main: '#111419'
    }
  }
});

const pages = ['About Us', 'Contact Us'];

export default function ButtonAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElMob, setAnchorElMob] = React.useState(false);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMob = () => {
    setAnchorElMob(!anchorElMob);
  };

  const handleHome = () => {
    setAnchorEl(null);
    navigate('/');
    window.scroll({top: 0});
  }

  const handleEventReady = () => {
    setAnchorEl(null);
    navigate('/event');
    window.scroll({top: 0});
  }

  const handleTaskFlow = () => {
    setAnchorEl(null);
    navigate('/task');
    window.scroll({top: 0});
  }

  const handleClickAboutContact = (page) => {
    if(page === 'About Us')
      navigate('/about');
    else
      navigate('/contact');
    window.scroll({top: 0});
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
          <Container className='container'>
            <Toolbar disableGutters>
              <Typography component="div" 
                sx={{ 
                  flexGrow: 1
                }}
              >
                <Typography component="div" 
                  sx={{ 
                    cursor: 'pointer',
                    width: 'fit-content',
                    display: 'flex',
                    alignItems: 'center'
                  }} 
                  onClick={handleHome}
                >
                  <Typography component="div" sx={{ lineHeight: 1, paddingRight: '5px' }}>
                    <img src={AppIcon} alt='' height='50px' width='50px'/>
                  </Typography>
                  <Typography variant="h6" component="div" className='font-face-sp'>
                    ActionTaskFlow
                  </Typography>
                </Typography>
              </Typography>
              <Box sx={{ flexGrow: 2, display: { xs:'none', md:'flex' } }}>
                <Button
                  sx={{ my: 2, color: 'white', display: 'flex', textTransform: 'none' }}
                  onClick={handleClick} className='font-face-sp'
                >
                  Our Products <KeyboardArrowDownIcon />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleEventReady} className='font-face-sp'>EventReady</MenuItem>
                  <MenuItem onClick={handleTaskFlow} className='font-face-sp'>TaskFlow</MenuItem>
                </Menu>
                {pages.map((page) => (
                  <Button
                    key={page}
                    sx={{ my: 2, color: 'white', display: 'block', textTransform: 'none' }}
                    onClick={() => handleClickAboutContact(page)} className='font-face-sp'
                  >
                    {page}
                  </Button>
                ))}
              </Box>
              <IconButton sx={{ color: 'white', display: { xs:'flex', md:'none' } }}>
                <MenuIcon onClick={handleClickMob}/>
              </IconButton>
            </Toolbar>
            <Typography component="div"
              sx={{ 
                display: { xs:'block', md:'none' }
              }}
            >
              <Typography component="div"
                sx={{ 
                  display: anchorElMob ? '' : 'none'
                }}
              >
                <MenuItem disableGutters disableRipple onClick={handleEventReady} className='font-face-sp'>
                  EventReady
                </MenuItem>
                <MenuItem disableGutters disableRipple onClick={handleTaskFlow} className='font-face-sp'>
                  TaskFlow
                </MenuItem>
                <MenuItem disableGutters disableRipple onClick={() => handleClickAboutContact('About Us')}
                  className='font-face-sp'>
                  About Us
                </MenuItem>
                <MenuItem disableGutters disableRipple onClick={() => handleClickAboutContact('contact')}
                  className='font-face-sp'>
                  Contact Us
                </MenuItem>
              </Typography>
            </Typography>
          </Container>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
}
