import AppBar from './AppBar';
import * as React from 'react';
import { Typography, Toolbar, Container, Grid, Button } from '@mui/material';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';

const AboutUs = () => {
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/contact');
  }

  return(
    <>
    <AppBar />
    <Toolbar />
    <Typography component="section" className="main-section-intro">
      <Container className="container">
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography component="div" 
              sx={{ p: '30px', mx: '10px', backgroundColor: '#111419', borderRadius: '25px',
                    width: { xs: '80%', md: '60%' } }}>
                <Typography variant='h4' sx={{ marginBottom: '1.5rem', textAlign: 'center' }}
                  className='font-face-sp'>
                  About ActionTaskFlow
                </Typography>
                <Typography component='div' sx={{ py: '10px' }} className='font-face-sp'>
                  Based in Perth, Western Australia. Our software solution company leverages our founder’s 
                  years of track record with asset management and software solutions.
                </Typography>
                <Typography component='div' sx={{ py: '10px' }} className='font-face-sp'>
                  Our solutions, at an enterprise level, are designed to be flexible, dynamic and work 
                  within your own organisation’s IT solutions or be managed by ourselves in a SaaS model. 
                </Typography>
                <Typography component='div' sx={{ py: '10px' }} className='font-face-sp'>
                  Whilst our solutions are developed to be dynamic, providing the ability to adapt your 
                  business needs through powerful administration controls, we also offer the ability to 
                  customise our solutions beyond what is provided, and will work with you to develop 
                  a tailored solution.
                </Typography>
                <Typography component='div' sx={{ py: '10px' }} className='font-face-sp'>
                  As an Australian based software solution company, we are also able to provide full 
                  support for bespoke solutions, through to providing access to our range of technical 
                  staff if you wish to internally uplift.
                </Typography>
                <Typography component="div" 
                  sx={{ py: '10px', display: 'flex', justifyContent: 'center' }}>
                  <Button variant="contained" onClick={handleContactClick} color="success"
                    sx={{ color: 'white', textTransform: 'none', px: '30px' }} className='font-face-sp'>
                      Get in touch
                  </Button>
                </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>

    <Footer />
  </>
  );
}

export default AboutUs;