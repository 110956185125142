import MainPage from './Components/MainPage';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import EventReady from './Components/EventReady';
import AboutUs from './Components/AboutUs';
import ContactUs from './Components/ContactUs';
import TaskFlow from './Components/TaskFlow';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/event" element={<EventReady />} />
        <Route path="/task" element={<TaskFlow />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
    </Router>
  );
}

export default App;
