import AppBar from './AppBar';
import Typography from '@mui/material/Typography';
import '../App.css';
import Footer from './Footer';
import { Toolbar, Container, Grid } from '@mui/material';
import Form from '../Media/tf-form.svg';
import DynamicForm from '../Media/tf-dynamic-form.svg';
import Roles from '../Media/tf-roles.svg';
import Monitor from '../Media/tf-monitor.svg';
import Report from '../Media/tf-report.svg';
import DataSource from '../Media/tf-data-source.svg';
import AutomateUser from '../Media/tf-automate-user.svg';

const TaskFlow = () => {
  return(
    <>
    <AppBar />
    <Toolbar />
    <Typography component="section" className="main-section-intro">
      <Container className="container">
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs={12}>
            <Typography component="div" 
              sx={{ p: '30px', mx: '10px', backgroundColor: '#111419', borderRadius: '25px' }}>
                <Typography variant='h4' sx={{ marginBottom: '1.5rem', textAlign: 'center' }}
                  className='font-face-sp'>
                  TaskFlow
                </Typography>
                <Typography component='div' sx={{ textAlign: 'center' }} className='font-face-sp'>
                  TaskFlow provides a powerful form builder integrating dynamic user, role and team 
                  management with intuitive macro views of role based workflow progress.
                </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>

    <Typography component="section" className="product-section-odd">
      <Container className="container">
        <Grid container spacing={5} sx={{ alignItems: 'center', py: '20px' }}>
          <Grid item xs={12} md={6}>
            <Typography component="div" 
              sx={{ py: '15px' }}>
                <Typography variant='div' sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                  className='font-face-sp'>
                  Form Builder
                </Typography>
                <Typography component='div' sx={{ lineHeight: '1.7rem', fontSize: '0.9rem' }}
                  className='font-face-sp'>
                  A range of intuitive form controls combined with simple logic statements provide the 
                  ability  to show, hide and workflow forms based on form entries and actions. Create 
                  libraries of terms relevant to your organisation and map them to different selectable 
                  controls across your forms.
                </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography component="div" 
              sx={{ py: '15px', display: 'flex', justifyContent: 'center' }}>
                <img src={Form} alt='' height='150px' />
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>

    <Typography component="section" className="product-section-even">
      <Container className="container">
        <Grid container spacing={5} sx={{ alignItems: 'center', py: '20px',
          flexDirection: { xs: 'column-reverse', md: 'row' } }}>
          <Grid item xs={12} md={6}>
            <Typography component="div" 
              sx={{ py: '15px', display: 'flex', justifyContent: 'center' }}>
                <img src={DynamicForm} alt='' height='150px' />
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography component="div" 
              sx={{ py: '15px' }}>
                <Typography variant='div' sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                  className='font-face-sp'>
                  Dynamic Form Management
                </Typography>
                <Typography component='div' sx={{ lineHeight: '1.7rem', fontSize: '0.9rem' }}
                  className='font-face-sp'>
                  Group forms by type, and create virtual teams to manage each separate grouping. 
                  Virtual teams can be formed by location, work roles, even combined with other teams. 
                  Variations are endless.
                </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>

    <Typography component="section" className="product-section-odd">
      <Container className="container">
        <Grid container spacing={5} sx={{ alignItems: 'center', py: '20px' }}>
          <Grid item xs={12} md={6}>
            <Typography component="div" 
              sx={{ py: '15px' }}>
                <Typography variant='div' sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                  className='font-face-sp'>
                  Roles and Permissions
                </Typography>
                <Typography component='div' sx={{ lineHeight: '1.7rem', fontSize: '0.9rem' }}
                  className='font-face-sp'>
                  Set  controls on what roles can access or view sections of a form. Users are limited 
                  to view and action forms within the tool based on their roles and team membership of 
                  which a user may have unlimited associations.
                </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography component="div" 
              sx={{ py: '15px', display: 'flex', justifyContent: 'center' }}>
                <img src={Roles} alt='' height='150px' />
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>

    <Typography component="section" className="product-section-even">
      <Container className="container">
        <Grid container spacing={5} sx={{ alignItems: 'center', py: '20px',
          flexDirection: { xs: 'column-reverse', md: 'row' } }}>
          <Grid item xs={12} md={6}>
            <Typography component="div" 
              sx={{ py: '15px', display: 'flex', justifyContent: 'center' }}>
                <img src={Monitor} alt='' height='150px' />
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography component="div" 
              sx={{ py: '15px' }}>
                <Typography variant='div' sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                  className='font-face-sp'>
                  Monitor, Notify and Escalate
                </Typography>
                <Typography component='div' sx={{ lineHeight: '1.7rem', fontSize: '0.9rem' }}
                  className='font-face-sp'>
                  Define the status of each form stage and track as it progresses. Identify the work roles being 
                  waited on, build notification rules to prompt, escalate and externally communicate according to 
                  your business processes and using notification templates with auto-populated form information.
                </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>

    <Typography component="section" className="product-section-odd">
      <Container className="container">
        <Grid container spacing={5} sx={{ alignItems: 'center', py: '20px' }}>
          <Grid item xs={12} md={6}>
            <Typography component="div" 
              sx={{ py: '15px' }}>
                <Typography variant='div' sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                  className='font-face-sp'>
                  Report, Audit and Improve
                </Typography>
                <Typography component='div' sx={{ lineHeight: '1.7rem', fontSize: '0.9rem' }}
                  className='font-face-sp'>
                  Create simple rules to export the completed form data to consume within your own 
                  reporting tools. Audit the time to complete form stages and by whom to enable 
                  de-bottlenecking and continual process improvement.
                </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography component="div" 
              sx={{ py: '15px', display: 'flex', justifyContent: 'center' }}>
                <img src={Report} alt='' height='150px' />
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>

    <Typography component="section" className="product-section-even">
      <Container className="container">
        <Grid container spacing={5} sx={{ alignItems: 'center', py: '20px',
          flexDirection: { xs: 'column-reverse', md: 'row' } }}>
          <Grid item xs={12} md={6}>
            <Typography component="div" 
              sx={{ py: '15px', display: 'flex', justifyContent: 'center' }}>
                <img src={DataSource} alt='' height='150px' />
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography component="div" 
              sx={{ py: '15px' }}>
                <Typography variant='div' sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                  className='font-face-sp'>
                  Combine Data Sources
                </Typography>
                <Typography component='div' sx={{ lineHeight: '1.7rem', fontSize: '0.9rem' }}
                  className='font-face-sp'>
                  Simplify integration with other sources of information.<br />
                  Define and consume APIs, control what is mapped, where it is 
                  displayed and when it is pulled into your forms.
                </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>

    <Typography component="section" className="product-section-odd">
      <Container className="container">
        <Grid container spacing={5} sx={{ alignItems: 'center', py: '20px' }}>
          <Grid item xs={12} md={6}>
            <Typography component="div" 
              sx={{ py: '15px' }}>
                <Typography variant='div' sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                  className='font-face-sp'>
                  Automate User ID
                </Typography>
                <Typography component='div' sx={{ lineHeight: '1.7rem', fontSize: '0.9rem' }}
                  className='font-face-sp'>
                  No need to sign in with Single Sign On supported via Azure. <br />
                  Users directed to an individualised view of of the tool based on 
                  their team membership and work roles.
                </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography component="div" 
              sx={{ py: '15px', display: 'flex', justifyContent: 'center' }}>
                <img src={AutomateUser} alt='' height='150px' />
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>

    <Footer />
  </>
  );
}

export default TaskFlow;