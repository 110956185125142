import AppBar from './AppBar';
import Typography from '@mui/material/Typography';
import '../App.css';
import Footer from './Footer';
import { Toolbar, Container, Grid } from '@mui/material';
import EventLibrary from '../Media/event-lib.svg';
import EventDashboard from '../Media/event-dash.svg';
import IndividualDashboard from '../Media/individual-dash.svg';
import Monitor from '../Media/event-monitor.svg';
import Review from '../Media/event-review.svg';

const EventReady = () => {
  return(
    <>
    <AppBar />
    <Toolbar />
    <Typography component="section" className="main-section-intro">
      <Container className="container">
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs={12}>
            <Typography component="div" 
              sx={{ p: '30px', mx: '10px', backgroundColor: '#111419', borderRadius: '25px' }}>
                <Typography variant='h4' sx={{ marginBottom: '1.5rem', textAlign: 'center' }}
                  className='font-face-sp'>
                  EventReady
                </Typography>
                <Typography component='div' sx={{ textAlign: 'center' }}className='font-face-sp'>
                  EventReady provides an ability to capture your organisation's tribal knowledge to 
                  facilitate and monitor readiness for an upcoming event with an intuitive, dynamic dashboard.
                </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>

    <Typography component="section" className="product-section-odd">
      <Container className="container">
        <Grid container spacing={5} sx={{ alignItems: 'center', py: '20px' }}>
          <Grid item xs={12} md={6}>
            <Typography component="div" 
              sx={{ py: '15px' }}>
                <Typography variant='div' sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                  className='font-face-sp'>
                  Event Libraries
                </Typography>
                <Typography component='div' sx={{ lineHeight: '1.7rem', fontSize: '0.9rem' }}
                  className='font-face-sp'>
                  Create a variety of event libraries by building a sequence of tasks to be completed
                  preceding, during and post an event. Group tasks by business defined categories and
                  assign responsibility to work role types.
                </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography component="div" 
              sx={{ py: '15px', display: 'flex', justifyContent: 'center' }}>
                <img src={EventLibrary} alt='' height='150px' />
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>

    <Typography component="section" className="product-section-even">
      <Container className="container">
        <Grid container spacing={5} sx={{ alignItems: 'center', py: '20px',
          flexDirection: { xs: 'column-reverse', md: 'row' } }}>
          <Grid item xs={12} md={6}>
            <Typography component="div" 
              sx={{ py: '15px', display: 'flex', justifyContent: 'center' }}>
                <img src={EventDashboard} alt='' height='150px' />
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography component="div" 
              sx={{ py: '15px' }}>
                <Typography variant='div' sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                  className='font-face-sp'>
                  Event Dashboard
                </Typography>
                <Typography component='div' sx={{ lineHeight: '1.7rem', fontSize: '0.9rem' }}
                  className='font-face-sp'>
                  Initiate an event dashboard by selecting an event library, a team and an event date. 
                  The dashboard provides a current view of task completion by category, outstanding 
                  actions and milestone completion. An overview screen provides the status of all 
                  current events.
                </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>

    <Typography component="section" className="product-section-odd">
      <Container className="container">
        <Grid container spacing={5} sx={{ alignItems: 'center', py: '20px' }}>
          <Grid item xs={12} md={6}>
            <Typography component="div" 
              sx={{ py: '15px' }}>
                <Typography variant='div' sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                  className='font-face-sp'>
                  Individual Dashboard
                </Typography>
                <Typography component='div' sx={{ lineHeight: '1.7rem', fontSize: '0.9rem' }}
                 className='font-face-sp'>
                  Each user has their own individual dashboard displaying all the tasks and actions 
                  required across all events based on their role and team membership. 
                </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography component="div" 
              sx={{ py: '15px', display: 'flex', justifyContent: 'center' }}>
                <img src={IndividualDashboard} alt='' height='150px' />
            </Typography>
          </Grid>
        </Grid>
        </Container>
    </Typography>

    <Typography component="section" className="product-section-even">
      <Container className="container">
        <Grid container spacing={5} sx={{ alignItems: 'center', py: '20px',
          flexDirection: { xs: 'column-reverse', md: 'row' } }}>
          <Grid item xs={12} md={6}>
            <Typography component="div" 
              sx={{ py: '15px', display: 'flex', justifyContent: 'center' }}>
                <img src={Monitor} alt='' height='150px' />
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography component="div" 
              sx={{ py: '15px' }}>
                <Typography variant='div' sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                  className='font-face-sp'>
                  Monitor Readiness
                </Typography>
                <Typography component='div' sx={{ lineHeight: '1.7rem', fontSize: '0.9rem' }}
                  className='font-face-sp'>
                  Review task and action completion at a category level or deep dive into individual work 
                  role responsibilites. Event supporting information can be shared via attaching files to 
                  the Event Pinboard or uploading defined lists of information as sheets.
                </Typography>
            </Typography>
          </Grid>
        </Grid>
        </Container>
    </Typography>

    <Typography component="section" className="product-section-odd">
      <Container className="container">
        <Grid container spacing={5} sx={{ alignItems: 'center', py: '20px' }}>
          <Grid item xs={12} md={6}>
            <Typography component="div" 
              sx={{ py: '15px' }}>
                <Typography variant='div' sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                  className='font-face-sp'>
                  Review, Action and Improve
                </Typography>
                <Typography component='div' sx={{ lineHeight: '1.7rem', fontSize: '0.9rem' }}
                  className='font-face-sp'>
                  Raise actions to address missing tasks or improvement initiatives. Create granularity 
                  in future event tasks by building-in task steps or attaching instructing documents. 
                  Review Task completion notes and attachments.
                </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography component="div" 
              sx={{ py: '15px', display: 'flex', justifyContent: 'center' }}>
                <img src={Review} alt='' height='150px' />
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>

    <Footer />
  </>
  );
}

export default EventReady;