import * as React from 'react';
import AppBar from './AppBar';
import Typography from '@mui/material/Typography';
import { Container, Grid, Toolbar, Button } from '@mui/material';
import '../App.css';
import IntroSvg from '../Media/intro.svg';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import ERBullet from '../Media/er-bullet.svg';
import TFBullet from '../Media/tf-bullet.svg';


const MainPage = () => {
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/contact');
    window.scroll({top: 0});
  }

  const handleEventReadyClick = () => {
    navigate('/event');
    window.scroll({top: 0});
  }

  const handleTaskflowClick = () => {
    navigate('/task');
    window.scroll({top: 0});
  }

  return(
  <>
    <AppBar />
    <Toolbar />
    <Typography component="section" className="main-section-intro">
      <Container className="container">
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs={12} md={7}>
            <Typography variant='h4' className='font-face-sp'
              sx={{ marginBottom: '0.8rem', marginTop: '1rem' }}>
              Simple, intuitive tools
            </Typography>
            <Typography component="p" className='font-face-sp'
              sx={{ p: '20px', marginBottom: '0.8rem', backgroundColor: '#111419',
                    marginLeft: '-20px' }}>
              ActionTaskFlow, an Australian software solutions company has developed enterprise 
              solutions for managing Actions, Tasks and Workflows.<br /><br />
              Our tools exemplify our team's experience in
              <Typography component="ul" className='font-face-sp' disableGutters>
                <li>Business Process Improvement</li>
                <li>Asset Maintenance</li>
                <li>Outage Management</li>
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography component="div" sx={{ textAlign: 'center', marginTop: '50px' }}>
              <img src={IntroSvg} alt='' height='210px' width='210px'/>
            </Typography>
          </Grid>
        </Grid>
        <Typography component="div">
          <Button variant="contained" onClick={handleContactClick} color="success"
            sx={{ color: 'white', textTransform: 'none', px: '30px', marginLeft: '-20px',
                  display: {xs: 'none', md: 'block' } }} className='font-face-sp'>
              Contact Us
          </Button>
        </Typography>
        <Typography component="div" sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" onClick={handleContactClick} color="success"
            sx={{ color: 'white', textTransform: 'none', px: '30px', marginTop: '20px',
                  display: {xs: 'block', md: 'none' } }} className='font-face-sp'>
              Contact Us
          </Button>
        </Typography>
      </Container>
    </Typography>

    <Typography component="section" className="main-section-products">
      <Container className="container">
        <Typography variant='h4' sx={{ textAlign: 'center' }} className='font-face-sp'>
          Our Products
        </Typography>
        <Typography component='div'
          sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography component='div' className='font-face-sp'
            sx={{ marginBottom: '2.8rem', width: '60%', fontSize: '0.9rem', 
                  p: '5px', textAlign: 'center', marginTop: '0.8rem' }}>
            Our cloud based tools provides the ability for your organisation to host on
            your own IT infrastructure or on our cloud as a SaaS solution.
          </Typography>
        </Typography>
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs={12} md={6} sx={{ paddingRight: '15px' }}>
            <Typography component='div' sx={{ display: 'flex' }}>
              <Typography component='div' sx={{ paddingRight: '15px' }}>
                <img src={ERBullet} alt='' height='110px' />
              </Typography>
              <Typography component='div' sx={{ marginBottom: '0.4rem' }}>
                <Typography variant='h5' sx={{ marginBottom: '0.4rem' }} className='font-face-sp'>
                  EventReady™
                </Typography>
                <Typography component='p' className='font-face-sp'>
                  EventReady provides an ability to capture your organisation's tribal knowledge to 
                  facilitate and monitor readiness for an upcoming event with an intuitive, dynamic dashboard.
                </Typography>
              </Typography>
            </Typography>
            <Button variant="contained" onClick={handleEventReadyClick} color="success"
              sx={{ color: 'white', textTransform: 'none', px: '30px', marginTop: '20px',
                    marginBottom: '0.8rem' }} className='font-face-sp'>
                More Info
            </Button>
          </Grid>
          <Grid item xs={12} md={6} sx={{ paddingRight: '15px' }}>
            <Typography component='div' sx={{ display: 'flex' }}>
              <Typography component='div' sx={{ paddingRight: '15px' }}>
                <img src={TFBullet} alt='' height='110px' />
              </Typography>
              <Typography component='div' sx={{ marginBottom: '0.4rem' }}>
                <Typography variant='h5' sx={{ marginBottom: '0.4rem' }} className='font-face-sp'>
                  TaskFlow™
                </Typography>
                <Typography component='p' className='font-face-sp'>
                  TaskFlow provides a powerful form builder integrating dynamic user, role and team 
                  management with  intuitive macro views of role based workflow progress.
                </Typography>
              </Typography>
            </Typography>
            <Button variant="contained" onClick={handleTaskflowClick} color="success"
              sx={{ color: 'white', textTransform: 'none', px: '30px', marginTop: '20px',
                    marginBottom: '0.8rem' }} className='font-face-sp'>
                More Info
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Typography>

    <Footer />
  </>
  );
}

export default MainPage;