import AppBar from './AppBar';
import * as React from 'react';
import { Typography, Toolbar, Container, Grid } from '@mui/material';
import Footer from './Footer';

const ContactUs = () => {
  return(
    <>
    <AppBar />
    <Toolbar />
    <Typography component="section" className="main-section-intro">
      <Container className="container">
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography component="div" 
              sx={{ p: '30px', mx: '10px', backgroundColor: '#111419', borderRadius: '25px',
                    width: { xs: '80%', md: '60%' } }}>
                <Typography variant='h4' sx={{ marginBottom: '1.5rem', textAlign: 'center' }}
                  className='font-face-sp'>
                  Contact Us
                </Typography>
                <Typography component='div' sx={{ py: '10px' }} className='font-face-sp'>
                  For a live demo or to find out how ActionTaskFlow can improve your company's 
                  productivity, please get in touch.
                </Typography>
                <Typography component='div' sx={{ py: '10px' }} className='font-face-sp'>
                  Email us: sales@actiontaskflow.com
                </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>

    <Footer />
  </>
  );
}

export default ContactUs;