import Typography from '@mui/material/Typography';
import '../App.css';
import { Grid } from '@mui/material';
import AppIcon from '../Media/app-icon.svg';

const Footer = () => {
  return(
    <>
      <Typography component="div"
        sx={{ 
          fontSize: '.83em',
          background: '#111419',
          py: '60px'
        }}
      >
        <Typography component="div"
          sx={{ 
            maxWidth: '1140px',
            mx: 'auto',
            px: '40px',
            color: 'white'
          }}
        >
          <Grid container>
            <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography component="div" sx={{ lineHeight: 1, paddingRight: '5px' }}>
                <img src={AppIcon} alt='' height='50px' width='50px'/>
              </Typography>
              <Typography component="div" sx={{ fontSize: '25px' }} className='font-face-sp'>
                ActionTaskFlow
              </Typography>
            </Grid>
            <Grid item md={6}></Grid>
            <Grid item xs={12} md={2}>
              <Typography component="div" sx={{ marginBottom: '25px', fontSize: '0.9rem' }}
                className='font-face-sp'>
                &copy; 2022-{new Date().getFullYear()} ActionTaskFlow
              </Typography>
            </Grid>
          </Grid>
        </Typography>
      </Typography>
    </>
  );
}

export default Footer;